let crossValErrors = {}
let targetAppErrors = {}
let isCrossValidating = false
let isTargetValidating = false
let latestTargetTimestamp = 0
let totalErrorCount = 0

const getResultErrorCount = errors => Object.values(errors).reduce((acc, val) => acc + val.length, 0)

const updateTotalErrorCount = () => {
  totalErrorCount = getResultErrorCount(crossValErrors) + getResultErrorCount(targetAppErrors)
}

export default {
  isValidating() {
    return isCrossValidating || isTargetValidating
  },
  hasErrors: () => !!totalErrorCount,
  totalErrorCount: () => totalErrorCount,
  startCrossValidating() {
    isCrossValidating = true
  },
  stopCrossValidating() {
    isCrossValidating = false
  },
  startTargetValidating(timestamp) {
    isTargetValidating = true
    if (timestamp && timestamp > latestTargetTimestamp) {
      latestTargetTimestamp = timestamp
    }
  },
  stopTargetValidating(timestamp) {
    if (timestamp && timestamp >= latestTargetTimestamp) {
      isTargetValidating = false
    }
  },
  setCrossValErrors(errors) {
    crossValErrors = errors || {}
    updateTotalErrorCount()
  },
  setTargetAppErrors(errors) {
    targetAppErrors = errors || {}
    updateTotalErrorCount()
  },
  getErrorsForFile(uploadFile) {
    const uploadUuid = uploadFile.getId()
    const errors = []
    if (crossValErrors?.[uploadUuid]) {
      errors.push(...crossValErrors[uploadUuid])
    }
    if (targetAppErrors?.[uploadUuid]) {
      errors.push(...targetAppErrors[uploadUuid])
    }

    return errors
  },
}
